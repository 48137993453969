import validate from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/pages/runtime/validate.js";
import setup_45layout_45global from "/usr/src/app/middleware/setup-layout.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  setup_45layout_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/src/app/middleware/auth.ts"),
  customer: () => import("/usr/src/app/middleware/customer.ts"),
  guest: () => import("/usr/src/app/middleware/guest.ts"),
  org: () => import("/usr/src/app/middleware/org.ts"),
  prod: () => import("/usr/src/app/middleware/prod.ts")
}