<script setup lang="ts">
import {
  DsUIProvider,
  DsToast,
  DsIcon,
  DsButton,
  DS_ICONS,
} from '@dreamshaper/ds-ui'
import { storeToRefs } from 'pinia'
import { useDuplicationToastStore } from './stores/duplicationToastStore'

useHead({
  link: [
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
    },
  ],
})

const { groupName } = useDuplicationToastStore()
const { isOpenTaskModal } = storeToRefs(useDuplicationToastStore())
</script>

<template>
  <DsUIProvider locale="en_US">
    <DsToast />

    <DsToast :group="groupName">
      <template #actions>
        <DsButton
          class="my-2 admin-button-text-white"
          color="mint"
          @click="() => (isOpenTaskModal = true)"
        >
          <template #leftIcon>
            <DsIcon
              type="svg"
              :icon="DS_ICONS.MagnifierCircle"
              fill="#fff"
            />
          </template>

          Click here to show tasks in progress
        </DsButton>
      </template>
    </DsToast>

    <div id="admin-tool-teleport" />

    <NuxtLayout>
      <template #default>
        <NuxtPage />
      </template>

      <template #container>
        <NuxtPage />
      </template>
    </NuxtLayout>
  </DsUIProvider>
</template>

<style lang="scss">
@import '@dreamshaper/ds-ui/dist/style.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.admin-button-text-white {
  color: var(--ds-white-base) !important;
}

.admin-button-shadow {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
</style>
