export default defineNuxtRouteMiddleware((to, _from) => {
  if (to.path?.includes('select-org') || to.path?.includes('login')) {
    setPageLayout('default')
  }

  if (
    !to.path.includes('reporting')
    && !to.path.includes('select-org')
    && !to.path.includes('login')
    && !to.path.includes('builder')
  ) {
    setPageLayout('main')
  }

  if (
    !to.path.includes('reporting')
    && !to.path.includes('select-org')
    && !to.path.includes('login')
    && to.path.includes('builder')
  ) {
    setPageLayout('builder')
  }

  if (to.path.includes('reporting')) {
    setPageLayout('reporting')
  }
})
