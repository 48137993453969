import revive_payload_client_3OhyFldnik from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nNHZeIjAGJ from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Pg6wgtiC9z from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MoaPsn7CfZ from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_AYydiNWQgO from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qc4ledYvwL from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5T4MNWptuy from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hfCH6CNMif from "/usr/src/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.6_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_0hbqEvJHyY from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vi2v64oq5mjppgj6xgqintrd6e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_oioN8Vb26n from "/usr/src/app/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.5_rollup@4.21.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import i18n_VfGcjrvSkj from "/usr/src/app/plugins/i18n.ts";
import sentry_3AyO8nEfhE from "/usr/src/app/plugins/sentry.ts";
export default [
  revive_payload_client_3OhyFldnik,
  unhead_nNHZeIjAGJ,
  router_Pg6wgtiC9z,
  payload_client_MoaPsn7CfZ,
  navigation_repaint_client_AYydiNWQgO,
  check_outdated_build_client_qc4ledYvwL,
  chunk_reload_client_5T4MNWptuy,
  plugin_vue3_hfCH6CNMif,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0hbqEvJHyY,
  plugin_client_oioN8Vb26n,
  i18n_VfGcjrvSkj,
  sentry_3AyO8nEfhE
]