import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useDuplicationToastStore = defineStore(
  'duplicationToastStore',
  () => {
    const isOpenTaskModal = ref<boolean>(false)
    const groupName = 'duplication-pages-to-show-tasks-modal'
    const duplicationToastDetail
      = 'the duplication is being processed, click on the button below to see the processing queue and the list of tasks'

    return {
      isOpenTaskModal,
      groupName,
      duplicationToastDetail,
    }
  },
)
